import * as React from 'react';


import { graphqlRequest } from "./ApiCall";

/**
 * 影響度取得GraphQl
 * @returns 
 * 
 */
export function getImpactCounter(apiParams: any) {
  let res: any = [];
  let param: any = [{
    mode: apiParams.mode,
    longitude: apiParams.longitude,
    latitude: apiParams.latitude,
    uuid: apiParams.uuid,
    distance: apiParams.distance,
  }];

  const params: any = {
    query: 'query',
    params: param,
  }

  return new Promise(resolve => {
    (async () => {
      try {
        let result: any = await graphqlRequest(params);
        if (result.data.data != null) {
          res = result.data.data.getImpactCounter;
          if (res.length > 0) {
            resolve(res)
          } else {
            resolve(res)
          }
        }
      } catch (e) {
        console.log(e);
      }
    })();
  })
}

/**
 * 受付簿取得GraphQl
 * @returns 
 */
export function getReceptionBookById(apiParams: any) {
  let param: any = [{
    mode: apiParams.mode,
    plan_area_id: apiParams.plan_area_id,
  }];

  const params: any = {
    query: 'query',
    params: param,
  }

  return new Promise(resolve => {
    (async () => {
      try {
        let result: any = await graphqlRequest(params);
        resolve(result.data.data)
      } catch (e) {
        console.log(e);
      }
    })();
  })
}

/**
 * 受付簿リスト取得GraphQl
 * @returns 
 */
export function planAreaSearchById(apiParams: any) {
  const param: any = [{
    mode: apiParams.mode,
    id: apiParams.id,
    manageCitiesFlg: apiParams.manageCitiesFlg,
    manageCities: apiParams.manageCities,
    manageStep: apiParams.manageStep,
    managePlansOutsideCities: apiParams.managePlansOutsideCities,
  }];
  const params: any = {
    query: 'query',
    params: param,
  }

  return new Promise(resolve => {
    (async () => {
      try {
        let result: any = await graphqlRequest(params);
        resolve(result.data.data.planAreaSearchById)
      } catch (e) {
        console.log(e);
      }
    })();
  })
}

/**
 * 受付簿リスト取得GraphQl
 * @returns 
 */
export function getReceptionBook(apiParams: any) {
  let param: any = [{
    mode: apiParams.mode,
    status_task_update_date: apiParams.status_task_update_date,
    status_id: apiParams.status_id,
    pic_type: apiParams.pic_type,
    pic_name: apiParams.pic_name,
    task_id: apiParams.task_id,
    plan_area_id: apiParams.plan_area_id,
    department_code: apiParams.department_code,
    district_code: apiParams.district_code,
    plan_area_name: apiParams.plan_area_name,
    plan_area_rfc: apiParams.plan_area_rfc,
    login_user_id: apiParams.login_user_id
  }];

  const params: any = {
    query: 'query',
    params: param,
  }

  return new Promise(resolve => {
    (async () => {
      try {
        let result: any = await graphqlRequest(params);
        resolve(result.data.data)
      } catch (e) {
        console.log(e);
      }
    })();
  })
}


/**
 * 複数取得graphQl
 * @returns 
 * 
 */

export function getMultipleData(apiParams: any) {
  let res: any = [];
  const params: any = {
    query: 'query',
    params: apiParams,
  }
  return new Promise(resolve => {
    (async () => {
      try {
        let result: any = await graphqlRequest(params);
        if (result.data.data != null) {
          res = result.data.data;
          resolve(res)
        }
      } catch (e) {
        console.log(e);
      }
    })();
  })
}


const DataManage = () => {
  return (<></>)
}

export default DataManage
