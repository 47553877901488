import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUtilityContext } from '../../utility-provider';
import { useUserContext } from '../../user-provider';
import { getReceptionBook } from '../../api/DataManage';
import { tokenLogin } from '../../utility/firebase';

interface ModalProps {
    props: {
        modalOpen: boolean,
        setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
        planAreaId: string | null,
    }
};

interface dataTypeList {
    id: string,
    status_task_update_date: string,
    elapsedTime: string,
    type: string,
    department: string,
    district: string,
    rfc: string,
    plan_area_id: string,
    candidateSiteName: string,
    assembly: string,
    pattern: string,
    branch: string,
    status_id: string,
    pic_type: string,
    pic_name: string,
    task_id: string,
    reception_no: string
}

//TableCellのヘッダースタイル変更
const TableCellSx = {
    backgroundColor: "#00CC5B",
    color: "#fff",
};
//TableRowのカーソルをポインターへ変更
const TableRowSx = {
    cursor: "pointer"
};

const ResearchBranchModal: React.FC<ModalProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();
    const user = useUserContext();

    const [propsObj, setPropsObj] = useState<dataTypeList[]>();

    //画面遷移
    const handleRowClick = (branchType: string) => {
        //画面遷移
        const url = process.env.REACT_APP_LOCATION_URL + `/LongPage?planAreaId=${props.planAreaId}&branch=${branchType}`
        window.open(url, '_blank')
    };

    const [userData, setUserData] = useState<any>({});

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        const sync = async () => {
            await tokenLogin(window.location.href)
                .then((val: any) => {
                    if (val === 'unprocessed') {
                        // 未処理の場合はバックドロップ消さない
                        return;
                    }
                    if (user.setUserData) {
                        user.setUserData(val as string);
                    }
                }).catch((e: any) => {
                    console.log(e)
                })
        }
        sync();
        if (user.userData !== '') {
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    //モーダルクローズ
    const handleResearchBranchClose = () => {
        props.setModalOpen(false);
    };

    useEffect(() => {
        if (props.planAreaId) {
            getReceptionBookList()
        }
    }, [props.planAreaId])

    const getReceptionBookList = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            callApi('getReceptionBook').then((res: any) => {
                if (res && res.getReceptionBook.length > 0) {
                    const tmpList: any = []
                    res.getReceptionBook.map((row: any) => {
                        if (row.plan_area_id === props.planAreaId) {
                            row.flagNo = setFlagNo(row.reception_no)
                            tmpList.push(row)
                        }
                    })
                    setPropsObj(tmpList)
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        }
    }

    const setFlagNo = (receptionNo: string) => {
        let flagNo: string = '';
        if (receptionNo !== null) {
            if (window.location.href.indexOf('planareamenu') !== -1) {
                flagNo = receptionNo.slice(-2);
            } else if (window.location.href.indexOf('StoreMenu') !== -1) {
                flagNo = receptionNo.slice(0, 1);
            }
        }

        return flagNo;
    };


    const callApi = async (target: string) => {
        if (target === 'getReceptionBook') {
            return await getReceptionBook({
                mode: "getReceptionBook",
                status_task_update_date: "",
                status_id: [],
                pic_type: [],
                pic_name: [],
                task_id: [],
                plan_area_id: "",
                department_code: [],
                district_code: [],
                plan_area_name: "",
                plan_area_rfc: [],
                login_user_id: userData.staff_number,
            })
        }
    }

    return (
        <Box>
            {/* 調査書枝選択画面モーダル */}
            <Dialog open={props.modalOpen} onClose={handleResearchBranchClose} fullWidth>
                <DialogTitle sx={{ textAlign: "center" }}>閲覧する枝を選択してください</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={TableCellSx}>ステータス</TableCell>
                                    <TableCell sx={TableCellSx}>更新日時</TableCell>
                                    <TableCell sx={TableCellSx}>フラグ</TableCell>
                                    <TableCell sx={TableCellSx}>枝</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {typeof propsObj !== "undefined" &&
                                    propsObj.map((value: any, index: any) => (
                                        <TableRow key={index} sx={TableRowSx} onClick={() => { handleRowClick(value.branch) }}>
                                            <TableCell>{value.status_id}</TableCell>
                                            <TableCell>{value.status_task_update_date}</TableCell>
                                            <TableCell>{value.flagNo}</TableCell>
                                            <TableCell>{value.branch}</TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ResearchBranchModal;
