import * as React from 'react';
import { useLocation, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { ref, uploadBytes } from "firebase/storage";
import { storage } from '../../../utility/firebase';
import { useUtilityContext } from '../../../utility-provider';
import { getGPSDateTimeStamp } from '../../../utility/exif';
import { getListPaginateFroFirebase, getTargetPage, getFileData, getFileMetadata, deleteFile } from '../../../utility/storage';
import { setReceptionBook } from '../../../features/receptionBookSlice';

import {
    Grid,
    Button,
    InputLabel,
    Typography,
    Pagination,
    Box,
} from "@mui/material";
import { Link } from "react-router-dom";


export interface uploadProps {
    props:{
    }
}


interface State {
    planAreaId: string;
}


const Upload:React.FC<uploadProps> = ({props}) => {
    const [photoDataList, setPhotoDataList] = React.useState([]);
    const utilityCtx = useUtilityContext();
    const dispatch = useAppDispatch();

    //URLパラメータ
    const [searchParams] = useSearchParams();
    const planAreaId = searchParams.get("planAreaId");
    const referrer = searchParams.get("referrer");
    
    const [pageToken, setPageToken] = React.useState<{ page: number; token: string;}[]>([])
    const [currentPage, setCurrentPage] = React.useState(1)
    const inputRef = React.useRef<HTMLInputElement>(null);

    // 受付簿
    const objReceptionBook = useAppSelector((state) => state.receptionBook.select);
    const rviewStoreCode = useAppSelector((state) => state.rviewStoreData.storeCode);
    const backRef = React.useRef<HTMLDivElement>(null);
    const rviewbackRef = React.useRef<HTMLDivElement>(null);
    const rviewStoreBackRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        dispatch(setReceptionBook({
            ...objReceptionBook,
            plan_area_id: planAreaId
        }))
    }, [])

    React.useEffect(() => {
        if (objReceptionBook.plan_area_id !== "") {
            ((async) => {
                getPhotoList(currentPage)
            })();    
        }
    }, [objReceptionBook])

    // firebaseから任意の写真一覧を取得
    const getPhotoList = async (page: number) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        let token = ""
        const path = '/'+objReceptionBook.plan_area_id+'/'+'photo'
        await getListPaginateFroFirebase(path, page, token)
        .then((data: any) => {
            (async ()=>{
                const result = await Promise.all(data?.item?.map( async(row: any) => {
                    await getFileData(row.fullPath)
                        .then((imageData: any) => {
                            row.imgPath = imageData
                        })
                        await getFileMetadata(row.fullPath)
                        .then((metaData: any) => {
                            row.metaData = metaData
                        })
                        setPhotoDataList(data.item)
                    })
                );
                const tmpToken:any = []
                tmpToken.push({page: 1, token: ''})
                if (data.nextPageToken) {
                    data.nextPageToken.map((row: any) => {
                        tmpToken.push(row)
                    })
                }

                setPageToken(tmpToken)
            }
            )();
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        })
    }

    // firebaseから任意の写真一覧を取得
    const getPage = async (page: number) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        let token = ""
        if (page !== 1) {
            pageToken.map((row) => {
                if (row.page === page) {
                    token =  row.token
                }
            })
        }
        const path = '/'+objReceptionBook.plan_area_id+'/'+'photo'
        await getTargetPage(path, page, token, pageToken)
        .then((data: any) => {
            (async ()=>{
                const result = await Promise.all(data?.item?.map( async(row: any) => {
                    await getFileData(row.fullPath)
                        .then((imageData: any) => {
                            row.imgPath = imageData
                        })
                        await getFileMetadata(row.fullPath)
                        .then((metaData: any) => {
                            row.metaData = metaData
                        })
                        setPhotoDataList(data.item)
                    })
                );
            }
            )();
        }).catch((e) => {
            console.log(e)
        }).finally(() => {
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        })
    }

    const getDate = (item: any) => {
        if (
            item.customMetadata !== undefined &&
            item.customMetadata.GPSDateTimeStamp !== '' &&
            item.customMetadata.GPSDateTimeStamp !== null
        ) {
            return item.customMetadata.GPSDateTimeStamp
        }
        return 'YYYY/MM/DD HH:MM:SS'
    }

    const handleChange = (event: any, value: number) => {
        setCurrentPage(value)
        getPage(value)
    };


    const handleChangeFile = (newFile: React.ChangeEvent<HTMLInputElement>) => {
        if (!newFile.target.files) return;
        if (newFile.target.files.length > 0) {
            (async () => {
                try {
                    if (utilityCtx.showSpinner) {
                        utilityCtx.showSpinner();
                    }
                    await uploader(newFile)
                    .then(() => {
                    }).catch((e) => {
                        console.log(e)
                    }).finally(() => {
                        setTimeout(() => {
                            setCurrentPage(1)
                            getPhotoList(1)
                            .then(() => {
                                if (utilityCtx.hideSpinner) {
                                    utilityCtx.hideSpinner();
                                }
                            })
                        }, 5000);
                    })
                } catch (e) {
                    console.log(e)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
            })();
        }
    };

    // ファイルアップロード
    const uploader = async (newFile: any) => {
        for (let i = 0; i < newFile!.target!.files!.length; i++) {
            const fileObject = newFile!.target!.files![i]!;
            const result: any = await getGPSDateTimeStamp(window.URL.createObjectURL(fileObject).toString())
            if (result) {
                const date = new Date();
                const dateString = date.getFullYear()
                    + (date.getMonth() + 1).toString().padStart(2, "0")
                    + date.getDate().toString().padStart(2, "0")
                    + '_'
                    + date.getHours().toString().padStart(2, "0")
                    + date.getMinutes().toString().padStart(2, "0")
                    + date.getSeconds().toString().padStart(2, "0");
                const storageRef = ref(storage, '/' + objReceptionBook.plan_area_id + '/photo/' + 'IMG_' + dateString + '_' + (i + 1).toString().padStart(2, '0'));
                let GPSDateTimeStamp = ""
                if (result['date'] !== '') {
                    GPSDateTimeStamp = result['date']
                } else {
                    // 写真を撮ってアップロード時は日付が空なので現在時刻を入れる
                    GPSDateTimeStamp = date.getFullYear() + '/'
                    + (date.getMonth() + 1).toString().padStart(2, "0") + '/'
                    + date.getDate().toString().padStart(2, "0") + ' ' +
                    date.getHours().toString().padStart(2, "0") + ':'
                    + date.getMinutes().toString().padStart(2, "0") + ':'
                    + date.getSeconds().toString().padStart(2, "0");
                }
                const metadata = {
                    customMetadata: {
                        GPSDateTimeStamp: GPSDateTimeStamp,
                    }
                };
                uploadBytes(storageRef, fileObject, metadata)
                .then(() => {
                }).catch((e) => {
                    console.log(e)
                })
            }
        }
    }

    const handleClick = () => {
        if(inputRef.current){
            inputRef.current.click();
        }
    }

    // ファイル削除
    const handleDeleteFile = async  (fileRow: any) => {
        (async () => {
            try {
                if (utilityCtx.showSpinner) {
                    utilityCtx.showSpinner();
                }
                await deleteFile(fileRow.fullPath)
                .then(() => {
                    setTimeout(() => {
                        setCurrentPage(1)
                        getPhotoList(1)
                        .then(() => {
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }    
                        })
                    }, 5000);
                }).catch((e) => {
                    console.log(e)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                });
            } catch (e) {
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }


    const clickBack = () => {

        if (referrer === "") {
            if (backRef) {
                backRef.current?.click();
            }
        } else if (referrer === "rview") {
            if (rviewbackRef) {
                rviewbackRef.current?.click();
            }            
        } else if (referrer === "rview-store") {
            if (rviewStoreBackRef) {
                rviewStoreBackRef.current?.click();
            }
        }
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} sx={{height: '5vh', textAlign: 'center', margin: '10px 0'}}>
                    <Box sx={{ display: 'flex'}}>
                        <Button
                            variant="outlined"
                            size="small"
                            sx={{margin: '4px', minWidth: '30px', padding: '10px 0',
                            boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)'
                             }}
                            onClick={clickBack}
                        >≪</Button>
                        {
                            referrer === "" &&
                            <Link to="/Photo"><div ref={backRef}></div></Link>
                        }
                        {
                            referrer === "rview" &&
                            <Link to={"/Planareamenu?planAreaId="+planAreaId}><div ref={rviewbackRef}></div></Link>
                        }
                        {
                            referrer === "rview-store" &&
                            <Link to={"/StoreMenu?storecode="+rviewStoreCode}><div ref={rviewStoreBackRef}></div></Link>
                        }
                        <Typography sx={{ margin: '0 10px'}}>{objReceptionBook.plan_area_id}：{objReceptionBook.plan_area_name}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{height: '64vh', overflow: 'auto'}}>
                    {
                        photoDataList.sort().map((item: any) => (
                            <Grid container sx={{display: 'flex'}} key={item.imgPath}>
                                <Grid item xs={6}>
                                    <img
                                        src={item.imgPath}
                                        style={{
                                            width: '90%',
                                            top: '0',
                                            right: '0',
                                            bottom: '0',
                                            left: '0',
                                            margin: 'auto',
                                            border: '1px solid black',
                                        }}
                                        loading="lazy"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputLabel>撮影日時</InputLabel>
                                    <InputLabel sx={{fontSize: '12px'}}>{getDate(item)}</InputLabel>
                                    <Button
                                    sx={{width: '50%', height: '30px', margin: '10px 0 0 50px', padding: '10px'}}
                                    variant="outlined" 
                                    color="error"
                                    onClick={(e)=>handleDeleteFile(item)}>削除</Button>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid item xs={12} sx={{height: '5vh', margin: '10px 0'}}>
                    <Pagination
                        count={pageToken.length}
                        color="primary"
                        page={currentPage}
                        onChange={handleChange} 
                    />
                </Grid>
                <Grid item xs={12} sx={{height: '5vh'}}>
                    <Button
                    sx={{width: '100%', padding: '20px'}}
                    variant="contained" 
                    color="success"
                    onClick={()=>handleClick()}>写真アップロード</Button>
                    <input
                        hidden
                        type="file"
                        multiple
                        accept="image/*"
                        ref={inputRef}
                        onChange={(e)=>handleChangeFile(e)}>
                    </input>
                </Grid>
            </Grid>
            
        </>
    )
}

export default Upload;